import { Button } from '@findigs/ui-kit/src/components/button';
import { useCallback } from 'react';
import styled from 'styled-components';
import type { UsePoliciesWarningModalState } from '../hooks/use-policies-warning-modal';
import ConfirmModalLayout from '../layouts/confirm-modal-layout';
import type { ModalProps } from '../state/modal';
import PetsIcon from '../images/modals/pets-warning-icon.inline.svg';

export interface PoliciesWarningModalProps extends ModalProps {
  options: UsePoliciesWarningModalState['options'];
}

const StyledConfirmModalLayout = styled(ConfirmModalLayout)`
  /** 768px - tablet **/
  @media (min-width: 48rem) {
    max-width: 32.75rem;

    .actions {
      justify-content: center;
    }
  }
`;

/**
 * Modal component internally used by the `usePoliciesWarningModal`
 * hook to warn applicants so they pay extra attention to the
 * policies page
 */
const PoliciesWarningModal = ({
  options: { onConfirm },
  hide,
}: PoliciesWarningModalProps) => {
  const _onConfirm = useCallback(() => {
    hide();
    if (onConfirm) {
      onConfirm();
    }
  }, [hide, onConfirm]);

  const Confirm = (
    <Button type="button" variant="FilledBrand" onClick={_onConfirm}>
      I understand
    </Button>
  );

  return (
    <StyledConfirmModalLayout
      onDismiss={_onConfirm}
      disableDismiss={true}
      icon={<PetsIcon aria-hidden={true} />}
      actions={[Confirm]}
    >
      <h2 className="text-t-sm mb-8">
        Pets can affect your approval. Please review policies carefully.
      </h2>
      <p className="text-b-md-regular text-muted">
        Since you&apos;re completing pet verification apart from your
        application, you will not receive personalized policy alerts as you
        enter your pet details.
        <br />
        <br />
        Please review the property&apos;s full pet policy on the next page to
        learn whether any restrictions apply to you and your pet(s).
        <br />
        <br />
        Pet restrictions and fees do not apply to verified assistance animals.
      </p>
    </StyledConfirmModalLayout>
  );
};

export default PoliciesWarningModal;
